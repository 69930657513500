import React, { useState } from 'react'
import CrewMasterKeys from 'src/constants/locale/key/CrewMaster'
import CrewAddForm from './CrewAddPage'
import Namespace from 'src/constants/locale/Namespace'
import { components, useTranslation, constants } from 'cng-web-lib'

const {
  form: { CngAddForm }
} = components

function AddPage({ history, showNotification }) {
  const [lockedOnFirstTab, setLockedOnFirstTab] = useState(true)
  const [id, setId] = useState('')
  const { translate } = useTranslation([
    Namespace.ACE_HIGHWAY_SHIPMENT,
    Namespace.UI_COMPONENT
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let crew = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.TITLE
    )
    return {
      crew
    }
  }

  return (
    <CrewAddForm
      history={history}
      showNotification={showNotification}
      onPostSubmitSuccess={(datum) => {
        setId(datum.id)
        setLockedOnFirstTab(false)
      }}
    />
  )
}

export default AddPage
