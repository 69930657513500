import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import { constants } from 'cng-web-lib'

const { locale: { key: { CommonValidationMessageKeys, } } } = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const regexAlphaNumericSpecChar = "^[a-zA-Z0-9!\"\',-./:;?=()_@./#&+\-,\\s]*$"
  const invalidSpecialCharMessage = "Invalid special character(s) detected."

  return Yup.object({
    acctId: Yup.string(),
    crewId: Yup.string().required(requiredMessage).matches(regexAlphaNumericSpecChar, invalidSpecialCharMessage),
    crewType: Yup.string().required(requiredMessage),
    scac: Yup.string().matches("^[a-zA-Z0-9]+$","Enter alphanumeric characters.").required(requiredMessage),
    customProximityId: Yup.string().matches("^[a-zA-Z0-9]+$","Enter alphanumeric characters."),
    firstName: Yup.string().required(requiredMessage).matches(regexAlphaNumericSpecChar, invalidSpecialCharMessage),
    middleName: Yup.string().matches(regexAlphaNumericSpecChar, invalidSpecialCharMessage),
    lastName: Yup.string().required(requiredMessage).matches(regexAlphaNumericSpecChar, invalidSpecialCharMessage),
    dob: Yup.date().validFormat().typeError(dateTypeErrorMessage).required(requiredMessage),
    nationality: Yup.string().required(requiredMessage).nullable(),
    telNo: Yup.string().nullable().max(12,"Max 12 characters are allowed").min(10,"Min 10 characters are allowed").matches("^[0-9*#+-]+$", "Enter valid phone number"),
    email:  Yup.string().nullable().max(255,"Max 255 characters are allowed ").min(5,"Min 5 characters are allowed").email("Enter valid Email id"),
    aciInternationalDoc: Yup.array(),

    aceId: Yup.string().max(10,"Max 10 characters are allowed ").nullable(),
    address1: Yup.string().max(35,"Max 35 characters are allowed ").nullable(),
    address2: Yup.string().max(35,"Max 35 characters are allowed ").nullable(),
    address3: Yup.string().max(35,"Max 35 characters are allowed ").nullable(),
    cityName: Yup.string().max(35,"Max 35 characters are allowed ").nullable(),
    state: Yup.string().nullable(),
    postalCode: Yup.string().max(10,"Max 10 characters are allowed ").nullable(),

    licenseNo: Yup.string().matches("^[a-zA-Z0-9]+$", "Enter alphanumeric characters.").nullable().max(35, "Max 35 characters are allowed"),
    licenseCountry: Yup.string().when('licenseNo', {
      is: (value)=>(value!==null && value!=="" && value!==undefined),
      then: Yup.string().nullable(),
    }).nullable(),
    licenseState: Yup.string().when('licenseCountry', {
      is: (value)=>(value!==null && value!=="" && value!==undefined),
      then: Yup.string().nullable(),
    }).nullable(),

    cdlNo: Yup.string().nullable().max(35,"Max 35 characters are allowed"),
    cdlCountry: Yup.string().when('cdlNo', {
      is: (value)=>(value!==null && value!=="" && value!==undefined),
      then: Yup.string().required(requiredMessage).nullable(),
    }).nullable(),
    cdlState: Yup.string().when('cdlCountry', {
      is: (value)=>(value!==null && value!=="" && value!==undefined),
      then: Yup.string().required(requiredMessage).nullable(),
    }).nullable(),

   })
}

export default makeValidationSchema
  