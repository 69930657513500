import CrewMasterApiUrls from 'src/apiUrls/CrewMasterApiUrls'
import FormProperties from './FormProperties'
import { Box, Card, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import { useFormContext } from 'react-hook-form'
import AlertDialog from '../../components/aciacehighway/AlertDialog'
import withFormState from '../../components/aciacehighway/hocs/withFormState'

const {
  button: { CngButton },
  form: { CngAddForm }
} = components

function CrewAddPage({ history, onSetLoading, showNotification }) {
  return (
    <Card>
      <Box p={2}>
        <CngAddForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap) => (
            <FormProperties.Fields
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: CrewMasterApiUrls.POST,
            successRedirect: pathMap.CREW_MASTER_LIST_VIEW
          }}
          renderButtonSection={(_, loading) => (
            <CustomButtonSection
              history={history}
              loading={loading}
              onSetLoading={onSetLoading}
            />
          )}
        />
      </Box>
    </Card>
  )
}

function CustomButtonSection(props) {
  const { history, loading, onSetLoading } = props
  const [alertDialog, setAlertDialog] = useState(false)
  const {
    formState: { isSubmitting }
  } = useFormContext()

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={isSubmitting}
            onClick={() => setAlertDialog(true)}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <CngButton
            color='primary'
            disabled={isSubmitting}
            type='submit'
            size='medium'
          >
            Save as draft
          </CngButton>
        </Grid>
      </Grid>
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, discard'
        open={alertDialog}
        onClose={() => setAlertDialog(false)}
        onCancel={() => setAlertDialog(false)}
        onConfirm={() => history.push(pathMap.CREW_MASTER_LIST_VIEW)}
        title='Discard'
      >
        All progress in this session will be lost and can't be restored. Are you
        sure about this?
      </AlertDialog>
    </>
  )
}

export default withFormState(CrewAddPage)
