import React, { useState, useRef, useEffect, useCallback } from 'react'
import { components, constants, useTranslation, useServices } from 'cng-web-lib'
import { generatePath } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, Chip, Grid } from '@material-ui/core'
import pathMap from '../../paths/pathMap'
import Namespace from 'src/constants/locale/Namespace'
import CrewMasterKeys from 'src/constants/locale/key/CrewMaster'
import CrewMasterApiUrls from 'src/apiUrls/CrewMasterApiUrls'
import MedpidApiUrls from 'src/apiUrls/AceHighwayMedpidApiUrls'
import { getStatusMetadata } from '../../common/NACommon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Table from '../../components/aciacehighway/Table'
import AlertDialog from '../../components/aciacehighway/AlertDialog'
import _ from 'lodash'
import moment from 'moment-timezone'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { fetchUser } from "src/views/userprofile/UserProfileService.js";

const {
  button: { CngButton },
  form: {
    field: { CngSelectField }
  },
  table: { useFetchCodeMaintenanceLookup },
  CngDialog
} = components

const {
  CodeMaintenanceType,
  filter: { BETWEEN, EQUAL, LIKE, IN }
} = constants

function TablePage(props) {
  const { deleteRecord, securedSendRequest } = useServices()

  const tableRef = useRef()
  const { history, showNotification } = props

  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation(Namespace.CREW_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  const [lookups, setLookups] = useState(null)
  const [loading, setLoading] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState({ open: false, crew: null })
  const [crewSubmitDialog, setCrewSubmitDialog] = useState({ open: false, crew: [] })
  const [user, setUser] = useState([]);
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest, fileForUserDetails);
  }, []);

  useEffect(() => {
    Promise.all([
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'HIGHWAY_CREW_TYPE' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'EQ_MASTER_STATUS' }
      ])
    ]).then(([crewType, status]) => {
      setLookups({ crewType, status })
    })
  }, [])

  function makeTranslatedTextsObject() {
    let acctId = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.ACCT_ID
    )
    let crewId = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.CREW_ID
    )
    let crewType = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.CREW_TYPE
    )
    let scac = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.SCAC
    )
    let aceId = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.ACE_ID
    )
    let customProximityId = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.CUSTOM_PROXIMITY_ID
    )
    let firstName = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.FIRST_NAME
    )
    let middleName = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.MIDDLE_NAME
    )
    let lastName = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.LAST_NAME
    )
    let dob = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.DOB
    )
    let nationality = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.NATIONALITY
    )
    let telNo = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.TEL_NO
    )
    let email = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.EMAIL
    )
    let status = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.STATUS
    )
    let submissionDate = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.SUBMISSION_DATE
    )
    let responseDate = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.RESPONSE_DATE
    )
    let submitSentRecords = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.SUBMIT_SENT_RECORDS
    )
    let submitSuccessMessage = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.SUBMIT_SUCCESS_MESSAGE
    )
    let submitErrorMessage = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.SUBMIT_ERROR_MESSAGE
    )
    let editButton = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.EDIT_BUTTON
    )
    let editSentRecords = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.EDIT_SENT_RECORDS
    )
    let deleteButton = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.DELETE_BUTTON
    )
    let deleteSentRecords = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.DELETE_SENT_RECORDS
    )
    let submitButton = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.SUBMIT_BUTTON
    )

    return {
      acctId,
      crewId,
      crewType,
      scac,
      aceId,
      customProximityId,
      firstName,
      middleName,
      lastName,
      dob,
      nationality,
      telNo,
      email,
      status,
      submissionDate,
      responseDate,
      submitSentRecords,
      submitSuccessMessage,
      submitErrorMessage,
      editButton,
      editSentRecords,
      deleteButton,
      deleteSentRecords,
      submitButton
    }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  // Currently unused, pending enhancment on Table component
  const getLookupFilterOptions = useCallback((key) => {
    if (!lookups || !lookups?.[key]) return []

    return Object.entries(lookups[key]).reduce(
      (acc, [key, value]) => [
        ...acc,
        {
          label: value,
          value: key,
          filterValue: { value: key }
        }
      ],
      []
    )
  }, [lookups])

  const columns = [
    {
      field: "crewId",
      title: translatedTextsObject.crewId,
    },
    {
      field: "crewType",
      title: translatedTextsObject.crewType,
      render: (data) => getLookupValue('crewType', data.crewType)
    },
    {
      field: "aceId",
      title: translatedTextsObject.aceId,
    },
    {
      field: "customProximityId",
      title: translatedTextsObject.customProximityId,
    },
    {
      field: "scac",
      title: translatedTextsObject.scac,
    },
    {
      field: "firstName",
      title: translatedTextsObject.firstName,
    },
    {
      field: "lastName",
      title: translatedTextsObject.lastName,
    },
    {
      field: "nationality",
      title: translatedTextsObject.nationality,
    },
    {
      field: "status",
      title: translatedTextsObject.status,
      render: (data) => {
        if (!data.status) return

        const status = getStatusMetadata(data.status)

        return (
          <Chip
            label={getLookupValue('status', data.status)}
            size='small'
            style={{
              backgroundColor: status.color,
              color: status.contrastColor
            }}
          />
        )
      }
    },
    {
      field: "submittedDate",
      title: translatedTextsObject.submissionDate,
      render: (rowData) =>
        rowData.submittedDate &&
        moment(rowData.submittedDate).tz('Canada/Eastern').format('DD/MM/YYYY HH:mm:ss')
    },
    {
      field: "responseDate",
      title: translatedTextsObject.responseDate,
      render: (rowData) =>
        rowData.responseDate &&
        moment(rowData.responseDate).tz('Canada/Eastern').format('DD/MM/YYYY HH:mm:ss')
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.crewId,
      type: 'textfield',
      name: 'crewId',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.crewType,
      type: 'checkbox',
      name: 'crewType',
      operatorType: IN,
      options: [
        {
          label: "ACI;Driver",
          value: "EJ",
          filterValue: {
            value: "EJ"
          }
        },
        {
          label: "ACI;Passenger",
          value: "QF",
          filterValue: {
            value: "QF"
          }
        },
        {
          label: "ACI;Crew",
          value: "CRW",
          filterValue: {
            value: "CRW"
          }
        },
        {
          label: "ACE;Crew",
          value: "FM",
          filterValue: {
            value: "FM"
          }
        },
        {
          label: "ACE;Driver",
          value: "VW",
          filterValue: {
            value: "VW"
          }
        },
        {
          label: "ACE;Passenger",
          value: "FL",
          filterValue: {
            value: "FL"
          }
        }
      ]
    },
    {
      label: translatedTextsObject.aceId,
      type: 'textfield',
      name: 'aceId',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.customProximityId,
      type: 'textfield',
      name: 'customProximityId',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.scac,
      type: 'textfield',
      name: 'scac',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.firstName,
      type: 'textfield',
      name: 'firstName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.lastName,
      type: 'textfield',
      name: 'lastName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.nationality,
      type: 'textfield',
      name: 'nationality',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.status,
      type: 'checkbox',
      name: 'status',
      operatorType: IN,
      options: [
        {
          label: "Draft",
          value: "DR",
          filterValue: {
            value: "DR"
          }
        },
        {
          label: "Sent",
          value: "ST",
          filterValue: {
            value: "ST"
          }
        },
        {
          label: "Accepted",
          value: "AC",
          filterValue: {
            value: "AC"
          }
        },
        {
          label: "Rejected",
          value: "RJ",
          filterValue: {
            value: "RJ"
          }
        },
        {
          label: "Deactivated",
          value: "DA",
          filterValue: {
            value: "DA"
          }
        }
      ]
    },
    {
      label: translatedTextsObject.submittedDate,
      type: 'daterange',
      name: 'submittedDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.responseDate,
      type: 'daterange',
      name: 'responseDate',
      operatorType: BETWEEN
    }
  ]

  function handleDeleteCrew() {
    setLoading(true)
    if (confirmDialog.crew) {
      deleteRecord.execute(CrewMasterApiUrls.DELETE, confirmDialog.crew,
        () => {
          showNotification('success', 'Crew deleted successfully.')
          setConfirmDialog({ open: false, crew: null })

          if (tableRef.current?.performRefresh) {
            tableRef.current.performRefresh()
          }
        },
        (error) => {
          console.log(error)
        }
      )
    }
    setLoading(false)
  }

  function handleSubmitCrew({ messageFunction }) {
    if (_.isEmpty(crewSubmitDialog.crew)) return

    setLoading(true)
    let result = [...crewSubmitDialog.crew]
    const fileForUserDetails = FileForUserGetUserDetails()

    result.forEach((crew) => {
      if (fileForUserDetails) {
        const { fileForUserId, fileForUserPartyId, fileForUserLoginId } = fileForUserDetails

        crew['fileForUserId'] = fileForUserId
        crew['fileForUserPartyId'] = fileForUserPartyId
        crew['fileForUserLoginId'] = fileForUserLoginId
      }

      crew['lastMsgAction'] = messageFunction
    })

    try {
      securedSendRequest.execute('POST', MedpidApiUrls.SUBMIT_ACE_CREW, result,
        (response) => {
          const { errorMessages } = response.data[0]
          
          if (!_.isEmpty(errorMessages)) {
            errorMessages.forEach((message) =>
              showNotification('error', message)
            )
          } else {
            showNotification(
              'success',
              translatedTextsObject.submitSuccessMessage
            )
          }
          if (tableRef.current?.performRefresh) {
            tableRef.current.performRefresh()
          }

          setCrewSubmitDialog({ open: false, crew: [] })
        },
        () => showNotification('error', translatedTextsObject.submitErrorMessage),
        () => setLoading(false)
      )
    } catch (error) {
      showNotification('error', translatedTextsObject.submitErrorMessage)
      setLoading(false)
    }
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.CREW_MASTER_ADD_VIEW)
            },
            label: 'Create Crew Master'
          }
        ]}
        checkboxSelection
        columns={columns}
        compact
        fetch={{ url: CrewMasterApiUrls.SEARCH }}
        customExportData={{ url: CrewMasterApiUrls.EXPORT_CSV, accId: user.loginId, partyId: FileForUserGetPartyId() }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) => {
          if (rowData.status === 'ST') {
            history.push(generatePath(pathMap.CREW_MASTER_DETAILS_VIEW, { id: rowData.id }), { headerId: rowData.crewId })
          } else {
            history.push(generatePath(pathMap.CREW_MASTER_EDIT_VIEW, { id: rowData.id }), { headerId: rowData.crewId })
          }
        }

        }
        rowActions={[
          {
            disabled: (rowData) => rowData.status === 'ST',
            label: 'Edit',
            icon: <FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />,
            onClick: (rowData) =>
              history.push(
                generatePath(pathMap.CREW_MASTER_EDIT_VIEW, { id: rowData.id }),
                { headerId: rowData.crewId }
              ),
            tooltip: (rowData) =>
              rowData.status === 'ST'
                ? translatedTextsObject.editSentRecords
                : null
          },
          {
            disabled: (rowData) => rowData.status && rowData.status !== 'DR',
            label: 'Delete',
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            onClick: (rowData) => setConfirmDialog({ open: true, crew: rowData }),
            tooltip: (rowData) => rowData.status && rowData.status !== 'DR'
                ? translatedTextsObject.deleteSentRecords
                : null
          }
        ]}
        selectActions={[
          {
            disabled: (rows) => {
              let shouldDisable = false

              rows.forEach((rowData) => {
                if (rowData.status === 'ST') {
                  shouldDisable = true
                }
              })

              return shouldDisable
            },
            icon: <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />,
            label: translatedTextsObject.submitButton,
            onClick: (rows) => setCrewSubmitDialog({ open: true, crew: rows }),
            tooltip: (rows) => {
              let includesSentRecords = false

              rows.forEach((rowData) => {
                if (rowData.status === 'ST') {
                  includesSentRecords = true
                }
              })

              return includesSentRecords ? translatedTextsObject.submitSentRecords : null
            }
          }
        ]}
        showNotification={showNotification}
        persistSettings
        tableRef={tableRef}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, crew: null })}
        onCancel={() => setConfirmDialog({ open: false, crew: null })}
        onConfirm={handleDeleteCrew}
        title='Discard'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
      <CrewSubmitDialog
        onClose={() => setCrewSubmitDialog(false)}
        onSubmit={handleSubmitCrew}
        open={crewSubmitDialog.open}
        loading={loading}
      />
    </>
  )
}

function CrewSubmitDialog(props) {
  const { onClose, onSubmit, open, loading } = props

  const methods = useForm({
    defaultValues: { messageFunction: '23' }
  })
  const { translate } = useTranslation(Namespace.CREW_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const cancelButton = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.CANCEL_BUTTON
    )
    const submitButton = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.SUBMIT_BUTTON
    )
    const submitDialogTitle = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.SUBMIT_DIALOG_TITLE
    )
    const msgFuncType = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.MSG_FUNC_TYPE
    )
    const msgFuncAdd = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.MSG_FUNC_ADD
    )
    const msgFuncDeactivate = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.MSG_FUNC_DEACTIVATE
    )
    const msgFuncModify = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.MSG_FUNC_MODIFY
    )

    return {
      cancelButton,
      submitButton,
      submitDialogTitle,
      msgFuncType,
      msgFuncAdd,
      msgFuncDeactivate,
      msgFuncModify
    }
  }

  return (
    <CngDialog
      customDialogContent={
        <FormProvider {...methods}>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CngSelectField
                  name='messageFunction'
                  label={translatedTextsObject.msgFuncType}
                  items={[
                    { text: translatedTextsObject.msgFuncAdd, value: '23' },
                    { text: translatedTextsObject.msgFuncDeactivate, value: '129' },
                    { text: translatedTextsObject.msgFuncModify, value: '36' }
                  ]}
                  isRequired
                  size='small'
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justify='space-between' spacing={2}>
                  <Grid item xs='auto'>
                    <CngButton color='secondary' onClick={onClose} size='medium' disabled={loading}>
                      {translatedTextsObject.cancelButton}
                    </CngButton>
                  </Grid>
                  <Grid item xs='auto'>
                    <CngButton onClick={methods.handleSubmit(onSubmit)} size='medium' disabled={loading}>
                      {translatedTextsObject.submitButton}
                    </CngButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </FormProvider>
      }
      dialogTitle={translatedTextsObject.submitDialogTitle}
      fullWidth
      maxWidth='md'
      onClose={onClose}
      open={open}
    />
  )
}

export default TablePage
