import React, { useEffect, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import CrewMasterKeys from 'src/constants/locale/key/CrewMaster'
import { Grid, Card, Typography, Divider } from '@material-ui/core'
import moment from 'moment'
import { components, constants, DateTimeFormatter } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useFormContext } from "react-hook-form";
import { useTranslation } from 'cng-web-lib'
import InternationalDocFormProperties from './InternationalDocFormProperties'
import { NaAceStateCodeAutoCompleteField } from 'src/components/na'
import {FileForUserGetUserDetails} from 'src/common/FileForUserCommon'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import CngSection from '../../components/cngcomponents/CngSection'
import LocalTable from '../../components/aciacehighway/LocalTable'
import DialogForm from '../../components/aciacehighway/DialogForm'
import AlertDialog from '../../components/aciacehighway/AlertDialog'

const {
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngSelectField,
      CngSwitchField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField
    },
  },
  table: { useFetchCodeMaintenanceLookup },
  CngGridItem,
} = components

const {
  CodeMaintenanceType,
  filter: { EQUAL, IN }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  crewId: "",
  crewType: "",
  scac: "",
  customProximityId: "",
  firstName: "",
  middleName: "",
  lastName: "",
  dob: "",
  nationality: "",
  telNo: "",
  email: "",
  internationalDoc: [],
  status: "DR",
  aceId: "",
  fastCard: "",
  gender: "",
  address1: "",
  address2: "",
  address3: "",
  cityName: "",
  state: "",
  postalCode: "",
  licenseNo: "",
  licenseCountry: "",
  licenseState: "",
  cdlNo: "",
  cdlCountry: "",
  cdlState: "",
  hazmatEndorsement: "",
  partyId: "",
  aciHighway: false,
  oriCreatedBy: "",
  oriUpdatedBy: "",
  oriSubmittedBy: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, isView, shouldHideMap }) {
  const [formDialog, setFormDialog] = useState({ open: false, document: null })
  const [confirmDialog, setConfirmDialog] = useState({ open: false, document: null })
  const { setValue, getValues, watch } = useFormContext()
  const status = watch('status')
  const crewType = watch('crewType')
  const internationalDoc = watch('internationalDoc')
  const state = watch('state')
  const licenseCountry = watch('licenseCountry')
  const licenseState = watch('licenseState')
  const cdlCountry = watch('cdlCountry')
  const cdlState = watch('cdlState')
  const hazmatEndorsement = watch('hazmatEndorsement')

  const { translate } = useTranslation(Namespace.CREW_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const [lookups, setLookups] = useState(null)
  const [isAce, setIsAce] = useState(null);

  useEffect(() => {
    Promise.all([
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        {
          field: 'codeType',
          operator: IN,
          value: ['HIGHWAY_TRAVEL_DOC_TYPE', 'ACIHWY_DOCUMENT_TYPE']
        }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        {
          field: 'codeType',
          operator: EQUAL,
          value: ['HIGHWAY_CREW_TYPE']
        }
      ])
    ]).then(([docType, highwayCrewType]) => {
      setLookups({ docType, highwayCrewType })
    })
  }, [])

  useEffect(() => {
    const fileForUserDetails = FileForUserGetUserDetails()

    if (fileForUserDetails) {
      const { fileForUserId, fileForUserPartyId, fileForUserLoginId } =
        fileForUserDetails

      setValue('fileForUserId', fileForUserId)
      setValue('fileForUserPartyId', fileForUserPartyId)
      setValue('fileForUserLoginId', fileForUserLoginId)
    }
  }, [])

  useEffect(() => {
    if (lookups) {
      setModule(getLookupValue('highwayCrewType', crewType))
    }
  }, [crewType, lookups])

  function setModule(crewType) {
    let currIsAce = isAce   //current isAce value
    let updatedIsAce = false  //updated isAce value

    if (crewType && 'ACE' === crewType.substring(0, 3)) {
      updatedIsAce = true
      setValue('aciHighway', false)
    } else {
      setValue('aciHighway', true)
    }

    if (currIsAce != updatedIsAce) {
      setIsAce(updatedIsAce)

      //null checker to prevent array reset on initial load
      if (currIsAce != null) {
        setValue('internationalDoc', [])
      }
    }
  }

  function makeTranslatedTextsObject() {
    let crewMaster = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.TITLE
    )
    let crewId = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.CREW_ID
    )
    let crewType = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.CREW_TYPE
    )
    let scac = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.SCAC
    )
    let customProximityId = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.CUSTOM_PROXIMITY_ID
    )
    let firstName = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.FIRST_NAME
    )
    let middleName = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.MIDDLE_NAME
    )
    let lastName = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.LAST_NAME
    )
    let dob = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.DOB
    )
    let nationality = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.NATIONALITY
    )
    let telNo = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.TEL_NO
    )
    let email = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.EMAIL
    )
    let internationalDoc = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.InternationalDoc.TITLE
    )
    let docType = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.InternationalDoc.DOC_TYPE
    )
    let docNo = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.InternationalDoc.DOC_NO
    )
    let country = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.InternationalDoc.COUNTRY
    )
    let expiryDate = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.InternationalDoc.EXPIRY_DATE
    )

    let status = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.STATUS
    )
    let aceId = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.ACE_ID
    )
    let gender = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.GENDER
    )
    let address1 = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.ADDRESS_1
    )
    let address2 = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.ADDRESS_2
    )
    let address3 = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.ADDRESS_3
    )
    let cityName = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.CITY_NAME
    )
    let state = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.STATE
    )
    let postalCode = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.POSTAL_CODE
    )
    let licenseNo = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.LICENSE_NO
    )
    let licenseCountry = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.LICENSE_COUNTRY
    )
    let licenseState = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.LICENSE_STATE
    )
    let cdlNo = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.CDL_NO
    )
    let cdlCountry = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.CDL_COUNTRY
    )
    let cdlState = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.CDL_STATE
    )
    let hazmatEndorsement = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.HAZMAT_ENDORSEMENT
    )

    return {
      crewMaster,
      crewId,
      crewType,
      scac,
      customProximityId,
      firstName,
      middleName,
      lastName,
      dob,
      nationality,
      telNo,
      email,
      internationalDoc,
      docType,
      docNo,
      country,
      expiryDate,
      status,
      aceId,
      gender,
      address1,
      address2,
      address3,
      cityName,
      state,
      postalCode,
      licenseNo,
      licenseCountry,
      licenseState,
      cdlNo,
      cdlCountry,
      cdlState,
      hazmatEndorsement
    }
  }

  const newInternationalDocFormProperties = {
    ...InternationalDocFormProperties,
    formikProps: {
      ...InternationalDocFormProperties.formikProps,
      initialValues: {
        ...InternationalDocFormProperties.formikProps.initialValues,
        isAce: isAce
      } 
    }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function handleAddInternationalDocument(data) {
    const internationalDoc = [...getValues('internationalDoc'), data]

    setValue('internationalDoc', internationalDoc)
    setFormDialog({ open: false, document: null })
  }

  function handleDeleteInternationalDocument() {
    if (!confirmDialog.document) return

    const internationalDoc = [...getValues('internationalDoc')].filter(
      (document) => document._id !== confirmDialog.document._id
    )

    setValue('internationalDoc', internationalDoc)
    setConfirmDialog({ open: false, document: null })
  }

  function handleEditInternationalDocument(data) {
    const internationalDoc = [...getValues('internationalDoc')]
    const index = internationalDoc.findIndex(
      (document) => document._id === data._id
    )

    internationalDoc[index] = data

    setValue('internationalDoc', internationalDoc)
    setFormDialog({ open: false, document: null })
  }

  const SectionDivider = (text) => {
    return (
      <Grid item xs={12} style={{ marginTop: '15px', marginBottom: '5px' }}>
        <Grid alignItems='center' container spacing={2}>
          <CngGridItem xs={5} sm='auto'>
            <Typography variant='body2'>{text}</Typography>
          </CngGridItem>
          <CngGridItem xs={7} sm><Divider /></CngGridItem>
        </Grid>
      </Grid>
    )
  }

	return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CngSection
            title={translatedTextsObject.crewMaster}
            subheader={
              <Typography color='textSecondary' variant='caption'>
                <Typography variant='inherit' color='error'>*</Typography>
                Mandatory fields
              </Typography>
            }
          >
            <Grid container spacing={1}>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.crewId}>
                <CngTextField
                  name="crewId"
                  label={translatedTextsObject.crewId}
                  isRequired
                  disabled={disabled}
                  size='small'
                  inputProps={{ maxLength: 15 }}
                  onBlur={(e) => setValue('crewId', e.target.value.toUpperCase())}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.crewType}>
                <CngCodeMasterAutocompleteField
                  name="crewType"
                  label={translatedTextsObject.crewType}
                  isRequired
                  codeType='HIGHWAY_CREW_TYPE'
                  disabled={disabled}
                  size='small'
                  lookupProps={{
                    label: (record) => [record.code, record.descriptionEn].join('; ')
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.scac}>
                <CngTextField
                  name="scac"
                  label={translatedTextsObject.scac}
                  isRequired
                  disabled={disabled}
                  size='small'
                  inputProps={{ maxLength: 4 }}
                  onBlur={(e) => setValue('scac', e.target.value.toUpperCase())}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.customProximityId}>
                <CngTextField
                  name="customProximityId"
                  label={translatedTextsObject.customProximityId}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.aceId}>
                <CngTextField
                  name="aceId"
                  label={translatedTextsObject.aceId}
                  disabled={status =='AC' || disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.status}>
                <CngCodeMasterAutocompleteField 
                  name="status"
                  label={translatedTextsObject.status}
                  disabled
                  size='small'
                  codeType='EQ_MASTER_STATUS'
                />
              </CngGridItem>

              {SectionDivider('Personal Details')}

              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.firstName}>
                <CngTextField
                  name="firstName"
                  label={translatedTextsObject.firstName}
                  isRequired
                  disabled={disabled}
                  size='small'
                  inputProps={{ maxLength: 40 }}
                  onBlur={(e) => setValue('firstName', e.target.value.toUpperCase())}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.middleName}>
                <CngTextField
                  name="middleName"
                  label={translatedTextsObject.middleName}
                  disabled={disabled}
                  size='small'
                  inputProps={{ maxLength: 40 }}
                  onBlur={(e) => setValue('middleName', e.target.value.toUpperCase())}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.lastName}>
                <CngTextField
                  name="lastName"
                  label={translatedTextsObject.lastName}
                  isRequired
                  disabled={disabled}
                  size='small'
                  inputProps={{ maxLength: 40 }}
                  onBlur={(e) => setValue('lastName', e.target.value.toUpperCase())}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.gender}>
                <CngSelectField
                  name='gender'
                  label={translatedTextsObject.gender}
                  isRequired
                  disabled={disabled}
                  size='small'
                  items={[
                    { text: 'Male', value: 'M' },
                    { text: 'Female', value: 'F' }
                  ]}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.dob}>
                <CngDateField
                  name="dob"
                  label={translatedTextsObject.dob}
                  isRequired
                  disabled={disabled}
                  size='small'
                  shouldDisableDate={(dob) => moment().isBefore(moment(dob))}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.nationality}>
                <CngCountryAutocompleteField
                  name="nationality"
                  label={translatedTextsObject.nationality}
                  isRequired
                  disabled={disabled}
                  size='small'
                  lookupProps={{
                    label: (record) =>
                      [record.code, record.descriptionEn].join('; ')
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.telNo}>
                <CngTextField
                  name="telNo"
                  label={translatedTextsObject.telNo}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={8} shouldHide={shouldHideMap.email}>
                <CngTextField
                  name="email"
                  label={translatedTextsObject.email}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.address1}>
                <CngTextField
                  name="address1"
                  label={translatedTextsObject.address1}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.address2}>
                <CngTextField
                  name="address2"
                  label={translatedTextsObject.address2}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.address3}>
                <CngTextField
                  name="address3"
                  label={translatedTextsObject.address3}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.cityName}>
                <CngTextField
                  name="cityName"
                  label={translatedTextsObject.cityName}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.postalCode}>
                <CngTextField
                  name="postalCode"
                  label={translatedTextsObject.postalCode}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.state}>
                <NaAceStateCodeAutoCompleteField 
                  name="state"
                  label={translatedTextsObject.state}
                  disabled={disabled}
                  size='small'
                  countryCode={["US", "CA", "MX"]}
                  key={state}
                />
              </CngGridItem>

              {SectionDivider('License Details')}

              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.licenseNo}>
                <CngTextField
                  name="licenseNo"
                  label={translatedTextsObject.licenseNo}
                  disabled={disabled}
                  size='small'
                  onBlur={(e) => setValue('licenseNo', e.target.value.toUpperCase())}
                  required={crewType === "VW"}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.licenseCountry}>
                <CngCodeMasterAutocompleteField
                  name="licenseCountry"
                  label={translatedTextsObject.licenseCountry}
                  disabled={disabled}
                  size='small'
                  codeType='ACIHWY_MANIFEST_COUNTRY'
                  key={licenseCountry}
                  onChange={() => setValue('licenseState', '')}
                  lookupProps={{
                    label: (record) =>
                      [record.code, record.descriptionEn].join('; ')
                  }}
                  required={crewType === "VW"}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.licenseState}>
                <NaAceStateCodeAutoCompleteField 
                  name="licenseState"
                  label={translatedTextsObject.licenseState}
                  disabled={disabled}
                  size='small'
                  countryCode={licenseCountry}
                  key={licenseState}
                  required={crewType === "VW"}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.cdlNo}>
                <CngTextField
                  name="cdlNo"
                  label={translatedTextsObject.cdlNo}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.cdlCountry}>
                <CngCodeMasterAutocompleteField
                  name="cdlCountry"
                  label={translatedTextsObject.cdlCountry}
                  disabled={disabled}
                  size='small'
                  codeType='ACIHWY_MANIFEST_COUNTRY'
                  key={cdlCountry}
                  onChange={() => setValue('cdlState', '')}
                  lookupProps={{
                    label: (record) =>
                      [record.code, record.descriptionEn].join('; ')
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.cdlState}>
                <NaAceStateCodeAutoCompleteField 
                  name="cdlState"
                  label={translatedTextsObject.cdlState}
                  disabled={disabled}
                  size='small'
                  countryCode={cdlCountry}
                  key={cdlState}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.hazmatEndorsement}>
                <CngSwitchField
                  name="hazmatEndorsement"
                  label={translatedTextsObject.hazmatEndorsement}
                  disabled={disabled}
                />
              </CngGridItem>
            </Grid>
          </CngSection>
        </Grid>
        <Grid item xs={12}>
          <Card variant='outlined'>
            <LocalTable
              columns={[
                {
                  title: translatedTextsObject.docType,
                  field: 'docType',
                  render: (data) => getLookupValue('docType', data.docType)
                },
                {
                  title: translatedTextsObject.docNo,
                  field: 'docNo',
                },
                {
                  title: translatedTextsObject.country,
                  field: 'country',
                },
              ]}
              data={internationalDoc}
              header={
                <Typography style={{ fontWeight: 600 }}>
                  {translatedTextsObject.internationalDoc}
                </Typography>
              }
              {...(!isView && {
                actions: [
                  {
                    color: 'primary',
                    buttonProps: { color: 'secondary' },
                    label: 'Add Travel Document',
                    icon: ['fal', 'plus-circle'],
                    onClick: () => setFormDialog({ open: true, document: null })
                  }
                ],
                rowActions: [
                  {
                    icon: ['fal', 'pen'],
                    onClick: (rowData) => setFormDialog({ open: true, document: rowData }),
                    tooltip: 'Edit'
                  },
                  {
                    icon: ['fal', 'trash'],
                    onClick: (rowData) => setConfirmDialog({ open: true, document: rowData }),
                    tooltip: 'Delete'
                  }
                ]
              })}
            />
          </Card>
        </Grid>
      </Grid>
      <DialogForm
        formProperties={newInternationalDocFormProperties}
        fullWidth
        isView={isView}
        maxWidth='md'
        open={formDialog.open}
        onClose={() => setFormDialog({ open: false, document: null })}
        onSubmit={
          formDialog.document
            ? handleEditInternationalDocument
            : handleAddInternationalDocument
        }
        title={`${formDialog.document ? 'Edit' : 'Add New'} International Document`}
        value={formDialog.document}
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, document: null })}
        onCancel={() => setConfirmDialog({ open: false, document: null })}
        onConfirm={handleDeleteInternationalDocument}
        title='Delete'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
	)
}

function toClientDataFormat(serverData) {
  let localData = { ...serverData }

  localData.dob = DateTimeFormatter.toClientDate(
    localData.dob
  );
  localData.internationalDoc.forEach((datum, index) => {
    datum['_id'] = index
    datum.expiryDate = DateTimeFormatter.toClientDate(
      datum.expiryDate
    );
  });
  
  localData['hazmatEndorsement'] = serverData.hazmatEndorsement === '1'

  return localData;
}

function toServerDataFormat(localData) {
  let serverData = { ...localData }

  serverData.dob = DateTimeFormatter.toServerDate(
    localData.dob
  );
  serverData.internationalDoc.forEach((datum) => {
    datum.expiryDate = DateTimeFormatter.toServerDate(
      datum.expiryDate
    );
  });
  
  serverData['hazmatEndorsement'] = localData.hazmatEndorsement ? '1' : '0'

  serverData.partyId = FileForUserGetPartyId()
  
  return serverData
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
