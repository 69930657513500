import React from 'react'
import { components, useTranslation } from 'cng-web-lib'
import { useParams, useLocation } from 'react-router-dom'
import CrewMasterKeys from 'src/constants/locale/key/CrewMaster'
import Namespace from 'src/constants/locale/Namespace'
import CrewEditForm from './CrewEditPage'
import ResponseForm from '../acehighway/response/MedpidResponse'

const { CngTabs } = components

function EditPage({ showNotification }) {
  const { id } = useParams()
  const loc = useLocation()
  const { translate } = useTranslation([Namespace.CREW_MASTER])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let crew = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.TITLE
    )

    let response = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.RESPONSE_TITLE
    )
    return {
      crew,
      response
    }
  }

  return (
    <CngTabs
      headerColor='primary'
      tabs={[
        {
          tabName: translatedTextsObject.crew,
          tabContent: (
            <CrewEditForm
              showNotification={showNotification}
              id={id}
            />
          )
        },
        {
          tabName: translatedTextsObject.response,
          tabContent: (
            <ResponseForm
              showNotification={showNotification}
              id={id}
              headerId={loc.state.headerId}
              docType='ACE_MEDPID_CREW'
            />
          )
        }
      ]}
    />
  )
}

export default EditPage
