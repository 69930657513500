import makeValidationSchema from './InternationalDocMakeValidationSchema'
import React from 'react'
import { components, useTranslation } from 'cng-web-lib'
import { useFormContext } from "react-hook-form";
import Namespace from 'src/constants/locale/Namespace'
import CrewMasterKeys from 'src/constants/locale/key/CrewMaster'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'

const {
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  docType: "",
  docNo: "",
  country: "",
  expiryDate: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { setValue, watch } = useFormContext()
  const docType = watch('docType')
  const isAce = watch('isAce')

  const { translate } = useTranslation(Namespace.CREW_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let docType = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.InternationalDoc.DOC_TYPE
    )
    let docNo = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.InternationalDoc.DOC_NO
    )
    let country = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.InternationalDoc.COUNTRY
    )
    let expiryDate = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.InternationalDoc.EXPIRY_DATE
    )

    return {
      docType,
      docNo,
      country,
      expiryDate
    }
  }

	return (
    <Grid container spacing={2}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.docType}>
        <CngCodeMasterAutocompleteField
          name="docType"
          label={translatedTextsObject.docType}
          isRequired
          disabled={disabled}
          codeType={isAce ? 'HIGHWAY_TRAVEL_DOC_TYPE' : 'ACIHWY_DOCUMENT_TYPE'}
          onChange={(e) => {
            if (isAce && "ET" !== e && "AIG" !== e) {
              setValue('country', '')
            }
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.docNo}>
        <CngTextField
          name="docNo"
          label={translatedTextsObject.docNo}
          isRequired
          disabled={disabled}
          onBlur={(e) => setValue('docNo', e.target.value.toUpperCase())}
          inputProps={{ maxLength: 30 }}

        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.country}>
        <CngCountryAutocompleteField
          name="country"
          label={translatedTextsObject.country}
          isRequired
          disabled={isAce && "ET" !== docType && "AIG" !== docType}
          lookupProps={{
            label: (record) =>
              [record.code, record.descriptionEn].join('; ')
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={isAce}>
        <CngDateField
          name="expiryDate"
          label={translatedTextsObject.expiryDate}
          isRequired
          shouldDisableDate={(expiryDate) => moment().isAfter(moment(expiryDate))}
          disabled={disabled}
        />
      </CngGridItem>
    </Grid>
	)
}


function toClientDataFormat(serverData) {
  return serverData
}

function toServerDataFormat(localData) {
  return localData
}


const InternationalDocFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default InternationalDocFormProperties
