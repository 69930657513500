import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    isAce: Yup.string(),
    docType: Yup.string().nullable().required(requiredMessage),
    docNo: Yup.string().matches("^[a-zA-Z0-9]+$", "Enter alphanumeric characters.").nullable().when('docType', {
      is: docType => docType && docType.length>0,
      then: Yup.string().required(requiredMessage),
      otherwise: Yup.string().nullable()
    }),
    country: Yup.string().nullable().when(['isAce', 'docType'], {
      is: (isAce, docType) => ("true" == isAce && ("ET" == docType || "AIG" == docType)) || "false" == isAce,
      then: Yup.string().required(requiredMessage),
      otherwise: Yup.string().nullable()
    }),
    expiryDate: Yup.string().nullable().when('isAce', {
      is: (isAce) => "false" == isAce,
      then: Yup.string().required(requiredMessage),
      otherwise: Yup.string().nullable()
    })
  })
}

export default makeValidationSchema
